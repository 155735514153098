<template>
  <div class="w-tool-item">
    <svg class="icon" aria-hidden="true">
      <use :xlink:href="'#' + icon"></use>
    </svg>
    <span class="w-tool-item__name" v-if="!link">{{name}}</span>
    <a class="w-tool-item__name" :href="link" v-else target="_blank">{{name}}</a>
    <div class="w-tool-item__img">
      <img :src="img" />
      <br>{{$t('login.scanQR')}}
      <br>{{remark}}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      remark: {
        type: String,
        default: ''
      },
      img: {
        type: String,
        default: ''
      },
      link: {
        type: String,
        default: ''
      }
    }
  }

</script>

<style lang="scss" scoped>
  .w-tool-item {
    position: relative;
    cursor: pointer;
    padding: 0 18px;
    line-height: 24px;
    height: 24px;
    font-size: 24px;
    color: #eaf2ff;
    border-right: none;

    +.w-tool-item {
      border-right: none;
    }

    &__img {
      position: absolute;
      transform: translateY(calc(100% + 10px));
      right: 0;
      bottom: 0;
      width: 150px;
      z-index: 1;
      opacity: 0;
      font-size: 14px;
      background-color: #FFF;
      color: #606266;
      text-align: center;
      box-shadow: 0 0 15px rgba(0, 0, 0, .15);
      border: 1px solid #409EFF;
      display: none;

      img {
        width: 0;
        height: 0;
      }
    }

    &:hover &__img {
      width: 150px;
      padding: 15px;
      opacity: 1;
      display: block;

      img {
        width: 120px;
        height: 120px;
      }
    }

    &__name {
      color: #eaf2ff;
      font-size: 16px;
      text-decoration: none;
    }
  }

</style>
